import { useLayoutEffect } from "react"

const useTheme = (currentTheme) => {
  useLayoutEffect(() => {
    // If the user's preference in localStorage is dark...
    if (currentTheme == "dark") {
      // ...let's toggle the .dark-theme class on the body
      document.firstElementChild.setAttribute("color-scheme", "dark")

      // Otherwise, if the user's preference in localStorage is light...
    } else if (currentTheme == "light") {
      // ...let's toggle the .light-theme class on the body
      document.firstElementChild.setAttribute("color-scheme", "light")
    }
  }, [currentTheme])
}

export default useTheme
