import React from 'react';

/**
 * This component will visually hide the content in the DOM, keeping it accessible
 * for the screen reader user.
 */
const VisuallyHidden = ({ children }) => (
  <span
    css={`
      &:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    `}
  >
    {children}
  </span>
);

export default VisuallyHidden;
