import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { rhythm } from '../utils/typography';
import GlobalStyle from '../global.style';

import Toggle from './toggle';
import { LayoutProvider } from '../context/layout-context';
import useLocalStorageState from './hooks/use-local-storage';
import { ProfileListFooter } from './profile-list';

require('prismjs/plugins/line-numbers/prism-line-numbers.css');

const HeadingH1 = styled.h1`
  /* Andy Bell fluid typography 
   * Ref: https://piccalil.li/tutorial/fluid-typography-with-css-clamp/ 
  */
  font-size: clamp(1.5rem, calc(1rem + 4vw), 3rem);
  margin-block-end: 0;
  margin-top: 0;
  color: var(--primary-color);
`;

const HeadingH3 = styled.h3`
  font-family: Montserrat, sans-serif;
  margin-block-end: 0;
  margin-top: 0;
`;

const HeadingLink = styled(Link)`
  box-shadow: none;
  text-decoration: none;
  color: var(--primary-color);
`;

const Container = styled.div`
  position: relative;
  width: min(${rhythm(24)}, 100%);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh; /* this is to push footer at the bottom of the page */
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`;

const Layout = ({ location, title, children }) => {
  const [view, setView] = useLocalStorageState('view', 'list');

  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  if (location.pathname === rootPath) {
    header = (
      <HeadingH1>
        <HeadingLink to="/">{title}</HeadingLink>
      </HeadingH1>
    );
  } else {
    header = (
      <HeadingH3>
        <HeadingLink to="/">{title}</HeadingLink>
      </HeadingH3>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Container>
        <header
          css={`
            display: flex;
            flex-wrap: wrap;
            gap: 0.25rem;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: ${rhythm(1)};
          `}
        >
          {header}
          <Toggle />
        </header>
        <main>
          <LayoutProvider value={{ view, setView }}>{children}</LayoutProvider>
        </main>
        <footer css={{ marginBlockStart: 'auto' }}>
          <ProfileListFooter />
        </footer>
      </Container>
    </>
  );
};

export default Layout;
