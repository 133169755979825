import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --focus-inner-color: #fff;
    --focus-outer-color: currentColor;

    /* border radius tokens */
    --border-radius-sm: 0.125rem;
    --border-radius-md: 0.25rem;
    --border-radius-lg: 0.5rem;
    --border-radius-xlg: 1rem;

    /* Font weight */
    --font-weight-normal: 400;
    --font-weight-semibold: 600;
    --font-weight-bold: 800;

  /* Colors --------------------------- */
    --white: #fff;
    --highlight-color: #00f5c426;
    --syntax-block-bg: #011627;

    /* All color will share the same hue and saturation */
    --base-pink: 340, 63%; 
    --base-blue: 204, 80%;
    --base-gray: 222, 10%;

    /* Color pallette; each color pallette is a partial value */
    --pallete-pink-50: var(--base-pink), 50%;
    --pallete-pink-70: var(--base-pink), 70%;

    --pallete-blue-40: var(--base-blue), 40%;
    --pallete-blue-50: var(--base-blue), 50%;

    --pallete-gray-10: var(--base-gray), 10%;
    --pallete-gray-15: var(--base-gray), 15%;
    --pallete-gray-30: var(--base-gray), 30%;
    --pallete-gray-40: var(--base-gray), 40%;
    --pallete-gray-70: var(--base-gray), 70%;
    --pallete-gray-90: var(--base-gray), 90%;


    /* Compose color based on its meaning using pallets above */
    --primary-color: hsla(var(--pallete-pink-50), 100%);
    --secondary-color-dark: hsla(var(--pallete-blue-40), 100%);
    --secondary-color-light: hsla(var(--pallete-blue-50), 100%);
    --tertiary-color: hsla(var(--pallete-gray-30), 100%);
    --neutral-light: hsla(var(--pallete-gray-70), 100%);
    --neutral-dark: hsla(var(--pallete-gray-40), 100%);
    --card-link-hover: var(--primary-color);
    --hr-color: var(--neutral-light);
    --inline-code-bg: hsla(var(--pallete-pink-70), 20%);




    /* Theme light ----------------*/
      color-scheme: light;
    --body-bg: hsla(var(--pallete-gray-90), 30%);
    --text-color: hsla(var(--pallete-gray-10),100%);
    --card-box-bg: var(--white);
    --syntax-lang-bg: var(--white);
    --card-link-color: var(--secondary-color-dark);
    --text-link-color: var(--secondary-color-dark);
    --text-small-color: var(--neutral-dark);
    --toggle-border-color: var(--neutral-light);
    --list-marker-color: var(--primary-color);

     /* Box shadow effect light */
     --card-box-shadow-sm: 0 1px 1px 1px hsla(225, 55%, 9%, 8%);
     --card-box-shadow-md: 0 16px 32px 0 rgba(10, 16, 34, 0.2), 0 0 0 transparent;

     /* --------------------------- */

  }

  @media(prefers-color-scheme: dark){
   :root {
     color-scheme: dark;
    --body-bg: hsla(var(--pallete-gray-10), 100%);
    --text-color: hsla(var(--pallete-gray-90),100%);
    --card-box-bg: hsla(var(--pallete-gray-15), 100%);
    --syntax-lang-bg: hsla(var(--pallete-gray-15), 100%);
    --card-link-color: var(--text-color);
    --text-link-color: var(--secondary-color-light);
    --text-small-color: var(--neutral-light);
    --toggle-border-color: var(--neutral-dark);
    --list-marker-color: var(--text-color);

     --card-box-shadow-sm: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
     --card-box-shadow-md:  rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
   }
  }

  [color-scheme="light"] {
    color-scheme: light;

  --body-bg: hsla(var(--pallete-gray-90), 30%);
  --text-color: hsla(var(--pallete-gray-10),100%);
  --card-box-bg: var(--white);
  --syntax-lang-bg: var(--white);
  --card-link-color: var(--secondary-color-dark);
  --text-link-color: var(--secondary-color-dark);
  --text-small-color: var(--neutral-dark);
  --toggle-border-color: var(--neutral-light);
  --list-marker-color: var(--primary-color);


   --card-box-shadow-sm: 0 1px 1px 1px hsla(225, 55%, 9%, 8%);
   --card-box-shadow-md: 0 16px 32px 0 rgba(10, 16, 34, 0.2), 0 0 0 transparent;
  }

  [color-scheme="dark"] {
     color-scheme: dark;
    --body-bg: hsla(var(--pallete-gray-10), 100%);
    --text-color: hsla(var(--pallete-gray-90),100%);
    --card-box-bg: hsla(var(--pallete-gray-15), 100%);
    --syntax-lang-bg: hsla(var(--pallete-gray-15), 100%);
    --card-link-color: var(--text-color);
    --text-link-color: var(--secondary-color-light);
    --text-small-color: var(--neutral-light);
    --toggle-border-color: var(--neutral-dark);
    --list-marker-color: var(--text-color);

     --card-box-shadow-sm: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
     --card-box-shadow-md:  rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

/* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }


  html {
    scroll-behavior: smooth;
    overflow-y: visible;
  }

  body {
    background-color:var(--body-bg);
    color:var(--text-color);
  }

  hr {
     background-color:var(--hr-color);
  }
   
  blockquote {
    color: var(--text-color);
    border-left-color: var(--text-color);
    font-size: unset;
    line-height: unset;
  }



  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  a {
  /* for the sake of color contrast accessibility */
   color:var(--text-link-color);
  }

  
  a:focus {
    outline: max(2px, 0.1em) solid currentColor;
  }

  /* anchor tag create by plugin */
  a.anchor {
    box-shadow: none;
  & svg {
     fill: var(--text-link-color);
     visibility: visible;
     }
  }


  /* markdown table styles */
  td, th {
    border-bottom-color: var(--text-color);
  }


  li::marker {
    color: var(--list-marker-color);
    font-size: 1.2rem;
  }

  /* All img tag must have alt attribute. For decorative images, provide empty value (alt="") */
  img:not([alt]) {
    outline: 2px dotted red;
    /* stylelint-disable */
    error: All img elements should have alt attribute;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Set core body defaults */
  body {
    --overflow-body: visible;
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    overflow: var(--overflow-body);
  }

  ol[class], ul[class] {
   list-style: none;
  }

  fieldset[class] {
     margin:0;
  }

  /* Adding some margin for coding block */
  pre[class*="language-"] {
     margin: 1.5em 0;
     font-size: 1rem; 
  }

  :not(pre) > code {
      background-color: var(--inline-code-bg);
      padding: 0.15rem 0.2rem;
      border-radius: var(--border-radius-sm)
  }


  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  /* Animate focus ring pop up; an adam argyle tip */
  @media (prefers-reduced-motion: no-preference) {
    :focus {
      transition: outline-offset .25s ease;
      outline-offset: 5px;
    }
  }

  /* Print styles */
  @media print {
    header,
    footer,
    nav {
      display: none;
    }
  }
`;

export default GlobalStyle;
