import { css } from 'styled-components';

const TABLET = '624px';
/**
 * CSS emotion utilities
 */
export const desktopOnly = css`
  display: none;

  @media screen and (min-width: ${TABLET}) {
    display: flex;
  }
`;

export const mobileOnly = css`
  display: inline-flex;

  @media screen and (min-width: ${TABLET}) {
    display: none;
  }
`;

/* modern css solution for screen reader only css */
export const srOnly = css`
  display: block;
  height: 0;
  width: 0;
  overflow: hidden;

  /* margin: 0 is only for neutralizing prism's styles for heading tag */
  margin: 0;
`;
