import { useEffect, useState } from "react"

const useLocalStorageState = (key, initialValue) => {
  /* lazy loading initial state */
  const [value, setValue] = useState(() => {
    if (typeof window === "undefined") return initialValue

    const persistedValue = localStorage.getItem(key)

    return persistedValue !== null ? persistedValue : initialValue
  })

  useEffect(() => {
    localStorage.setItem(key, value)
  }, [key, value])

  return [value, setValue]
}

export default useLocalStorageState
