import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import useTheme from './hooks/use-theme';
import useLocalStorageState from './hooks/use-local-storage';
import { MoonIcon, SunIcon } from './icon';
import VisuallyHidden from './visually-hidden';
import { desktopOnly, mobileOnly } from '../utils/css';

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  white-space: nowrap;

  legend {
    font-size: 2px;
    opacity: 0;
    position: absolute;
  }

  label {
    display: inline-block;
    line-height: 2;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  label:first-of-type {
    padding-right: 5rem;
  }

  label:last-child {
    margin-left: -4.25rem;
    padding-left: 5rem;
  }

  &:focus-within label:first-of-type::after {
    box-shadow: 0 0 0 2px var(--white), 0 0 0 4px var(--secondary-color-dark);
  }

  /* making the switch UI */
  label:first-of-type::before,
  label:first-of-type::after {
    border: 1px solid var(--toggle-border-color);
    content: "";
    height: 2em;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    vertical-align: middle;
  }

  label:first-of-type::before {
    background: var(--white);
    border-radius: 100%;
    position: absolute;
    right: -0.075em;
    transform: translateX(0em);
    transition: transform 0.2s ease-in-out;
    width: 2em;
    z-index: 2;
  }

  label:first-of-type::after {
    background: #222;
    border-radius: 1em;
    margin: 0 1em;
    transition: background 0.2s ease-in-out;
    width: 4em;
  }

  input:first-of-type:checked ~ label:first-of-type::after {
    background: hsla(var(--pallete-gray-10), 100%);
  }

  input:first-of-type:checked ~ label:first-of-type::before {
    transform: translateX(-2em);
  }

  /* 
   * Use this selector if you want to change
   * svg styling based on selected option
   * input:checked + label > svg {
    stroke-width: 4px;
    }
   */

  /* Move the 2nd label to have a lower z-index, so when that
   option is toggled, the first label will overlay on top of the
   Switch ui, and the switch can be pressed again to toggle back
   to the prevoius state. */
  input:last-of-type:checked ~ label:last-of-type {
    z-index: 1;
  }

  label:hover svg {
    color: var(--primary-color);
  }
`;

/*
 * NOTE: JavaScript is often executed after CSS,
 * so this approach is prone to a “flash of incorrect theme” (FOIT).
 */
const Toggle = () => {
  // Check for dark mode preference at the OS level
  const preferredTheme = useMemo(
    () =>
      /* The following check is required, as window is not available server side rendering */
      (typeof window !== 'undefined'
        ? window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light'
        : 'light'),
    [],
  );

  /*
   * Set theme
   */
  useTheme(preferredTheme);

  const [theme, setTheme] = useLocalStorageState('theme', preferredTheme);

  /* Set "color-scheme" attribute on "html" element, whenever user
   * toggles the radio button
   */
  useEffect(() => {
    document.firstElementChild.setAttribute('color-scheme', theme);
  }, [theme]);

  const handleChange = (event) => {
    setTheme(event.target.value);
  };

  const handleToggle = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const buttonTitle = `Switch to ${theme === 'light' ? 'dark' : 'light'} theme`;
  return (
    <>
      <ToggleButton
        type="button"
        onClick={handleToggle}
        aria-labelledby="toggle-btn"
        title={buttonTitle}
        css={mobileOnly}
      >
        <span id="toggle-btn" hidden>
          Switch to&nbsp;
          {theme === 'light' ? 'dark' : 'light'}
          &nbsp; theme.
        </span>
        {theme === 'light' ? <SunIcon /> : <MoonIcon />}
      </ToggleButton>
      <Fieldset css={desktopOnly}>
        <legend>Settings</legend>
        <input
          type="radio"
          name="theme"
          id="toggle-light"
          onChange={handleChange}
          value="light"
          defaultChecked={theme === 'light'}
        />
        <label htmlFor="toggle-light">
          <VisuallyHidden>Light theme</VisuallyHidden>
          <SunIcon
            css={`
              vertical-align: middle;
            `}
          />
        </label>

        <input type="radio" name="theme" id="toggle-dark" value="dark" onChange={handleChange} />
        <label htmlFor="toggle-dark">
          <VisuallyHidden>Dark theme</VisuallyHidden>
          <MoonIcon
            css={`
              vertical-align: middle;
            `}
          />
        </label>
      </Fieldset>
    </>
  );
};

export default Toggle;

const ToggleButton = styled.button`
  display: inline-flex;
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;
  color: var(--text-color);
  padding-inline: 6px;
  margin-inline-end: -6px; /* To align right */
  :focus,
  :active,
  :hover {
    color: var(--primary-color);
  }
`;
