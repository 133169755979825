import React from 'react';
import styled from 'styled-components';

import { srOnly } from '../utils/css';
import { GithubIcon, LinkedinIcon, TwitterIcon } from './icon';

export const ProfileListFooter = () => (
  <>
    <h3 id="author-profile-footer" css={srOnly}>
      Amandeep Singh profile
    </h3>
    <Ul aria-labelledby="author-profile-footer" css={{ paddingBlockStart: '2rem' }}>
      <li>
        <a href="https://twitter.com/amn_sngh">
          <TwitterIcon size="small" />
          &nbsp;twitter
        </a>
      </li>
      <li>
        <a href="https://github.com/singhArmani">
          <GithubIcon size="small" />
          &nbsp;github
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/amandysingh/">
          <LinkedinIcon size="small" />
          &nbsp;linkedin
        </a>
      </li>
    </Ul>
  </>
);

export const ProfileListHeader = () => (
  <>
    <h2 id="author-profile-header" css={srOnly}>
      Amandeep Singh profile
    </h2>
    <Ul aria-labelledby="author-profile-header">
      <li>
        <a href="https://twitter.com/amn_sngh">
          <TwitterIcon size="small" />
          &nbsp;
          <Text>twitter</Text>
        </a>
      </li>
      <li>
        <a href="https://github.com/singhArmani">
          <GithubIcon size="small" />
          &nbsp;
          <Text>github</Text>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/amandysingh/">
          <LinkedinIcon size="small" />
          &nbsp;
          <Text>linkedin</Text>
        </a>
      </li>
    </Ul>
  </>
);

// Styled component ------------------

const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  color: var(--text-color);
  gap: var(--gap, 1.5rem);
  margin-block-end: var(--space-bottom, 0);
  margin-inline: 0;
  justify-content: center;

  li {
    margin: 0;
    padding: 0;
  }

  li a {
    box-shadow: none;
  }

  li:hover a {
    color: var(--primary-color);
  }
`;

const Text = styled.span`
  display: inline-block;
  ${srOnly};

  @media screen and (min-width: 624px) {
    display: inline;
    overflow: visible;
  }
`;
